import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Grid from '../grid/grid'
import { getSize } from '../grid/helper'
import useViewportSize from '../../utils/useViewportSize'

const HomeGrid = ({
  fetchRecommendations,
  articles,
  resetArticles,
  showVoting = false,
  language = 'ar'
}) => {
  const { width } = useViewportSize()

  useEffect(() => {
    // fetch page zero data
    fetchRecommendationsData({
      page: 0,
      refresh: false,
      gridSize: getSize(width)
    })
  }, [])

  const fetchRecommendationsData = ({
    page = articles.page,
    refresh,
    gridSize
  }) => {
    fetchRecommendations({
      page,
      gridLength: articles.data.length,
      lang: language,
      gridSize,
      refresh: refresh ? refresh : articles.firstRequest
    })
  }

  return (
    <Grid
      mode="homepage"
      size={16}
      language={language}
      gridData={articles}
      key="home-grid"
      fetchRecommendationsData={fetchRecommendationsData}
      resetArticles={resetArticles}
      showVoting={showVoting}
    />
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRecommendations: (payload) => {
      dispatch({ type: 'ARTICLES_REQUESTED', payload })
    },
    resetArticles: () => {
      dispatch({ type: 'ARTICLES_RESET_REQUEST' })
    }
  }
}

const mapStateToProps = ({ articles }) => {
  return {
    articles
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeGrid)
