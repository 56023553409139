import React, { useEffect } from 'react'
import { graphql } from 'gatsby'

import HomeGrid from '../components/home/homeGrid'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { addGoogleAnalyticsPageData } from '../utils/googleAnalyticsEvents'

const Vote = ({ data, location }) => {
  const siteTitle =
    'شارك باستطلاعات الرأي وصوت الاَن | الشرق للأخبار' ||
    data.site.siteMetadata.title
  const description = `شاركنا رأيك وصوت فيما يتعلق بأحدث الأخبار الاقتصادية والسياسية والاجتماعية والمجريات في الوطن العربي وجميع أنحاء 
  العالم عبر استطلاع الرأي من الشرق للأخبار.`

  const imageUrl =
    'https://img-staging.asharq.com/5dcadecd-eeae-4b07-a158-704cf5ea6273.jpg?ixlib=js-2.3.1&s=0b7cbfe91e688ac3da4d89fecd2e08e1'

  useEffect(() => {
    addGoogleAnalyticsPageData()
  }, [])

  return (
    <Layout location={location} title={siteTitle} lang={'ar'}>
      <SEO
        title={siteTitle}
        description={description}
        meta={[
          {
            property: `og:image`,
            content: imageUrl
          },
          {
            property: `og:title`,
            content: siteTitle
          },
          {
            property: `og:image:type`,
            content: `image/jpg`
          },
          {
            property: `og:image:alt`,
            content: description
          },
          {
            property: `og:description`,
            content: description
          },
          {
            property: `og:image:secure_url`,
            content: imageUrl
          },
          {
            property: `og:image:url`,
            content: imageUrl
          },
          {
            property: `og:url`,
            content: `https://asharq.com/vote/`
          }
        ]}
        lang="ar"
        canonicalUrl={`${process.env.GATSBY_BASE_URL}vote/`}
      />
      <HomeGrid showVoting />
    </Layout>
  )
}

export default Vote

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
